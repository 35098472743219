<template>
	<div>
		<div class="box">
			<div class="img_box">
				<img src="@/assets/image/liability/error.png" />
			</div>
			<div class="title">健康或职业评估不通过</div>
			<div>非常抱歉，该被保险人未能通过本次投保审核</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
	width: 4.8rem;
	font-size: 0.24rem;
	margin: 2.88rem auto 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 0.24rem;
	.img_box {
		width: 1.4rem;
		height: 1.4rem;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.title {
		font-size: 0.36rem;
		color: rgba(0, 0, 0, 0.8);
		margin: 0.4rem 0 0.2rem;
	}
}
</style>
